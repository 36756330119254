import React, { Component, Fragment, useEffect, useState } from 'react';
import { render } from 'react-dom';
import ReactDatatable from '@ashvin27/react-datatable';
import DataTable, { TableColumn, TableStyles } from "react-data-table-component";
import Header from "components/Headers/Header.js";
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import axios from "axios"
import { Link } from 'react-router-dom';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import TextEditor from './textEditor';
import Alert from './alert';
import movies from "./data";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MessageShowModal = (props) => {
const {message, toggle, visible} = props
    return (
        <div>
            <Modal
                isOpen={visible}
                toggle={toggle}
                size="md"
                // style={{maxWidth: '700px', width: '100%'}}
                className="lg">
                <ModalHeader className='modal_header_query' toggle={toggle} charCode={<i className='fa fa-close '></i>}>

                    <h3>Query Message</h3>
                </ModalHeader>
                <ModalBody>
                 <p>{message}</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
         
            <ToastContainer/>
        </div>
    )
}

export default MessageShowModal;
