// SKIP_PREFLIGHT_CHECK=true
module.exports = {
    development: {
      // site_api: "http://localhost:3000"
      // site_api: "http://194.163.34.12:3000"
      site_api: "https://backend.partycrack.com.au:3000"


    },
    production: {
      site_api: "https://backend.partycrack.com.au:3000"
      // site_api: "http://52.90.198.129:3000"
    },
  };



  