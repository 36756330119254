import React, { useState, useEffect } from 'react'
// import { useHistory  } from "react-router-dom"


import swal from "sweetalert";

import ReactJsAlert from "reactjs-alert"

const Alert = (props) => {
  const {isAlert, isAlertToggle, alertMessage, isAlertToggleClose} = props
  
  return (
    <div>

    <ReactJsAlert
    status={isAlert}   // true or false
    type="success"   // success, warning, error, info
    title={alertMessage}   // title you want to display
    Close={isAlertToggleClose}   // callback method for hide
/>    
    </div>
  )
}
export default Alert